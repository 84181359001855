import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useCompany, useUser} from '../../../hooks';
import {
  useFetchCustomer,
  useFetchDecoration,
  useFetchProduct,
  useFetchSalesDoc,
  useFetchVendor,
  useGetMarkups,
  useSalesDocCache,
  useSaveSalesDoc
} from '../../../hooks/api';
import {SalesDoc} from '../Models/SalesDoc';
import {AutoSaveService} from '../../../services';
import {randomString} from '../../../utils';
import {fullName} from '../../../utils/fullName';
import {Markups} from '../../../models';
import {featureFlags} from '../../../utils/featureFlag';

export function useSalesDocState({salesDocNumber, revisionId, templateId, autoSaveKey, action}) {
  const {user} = useUser();
  const [salesDoc, _setSalesDoc] = useState(null);
  const [changed, setChanged] = useState(false);
  const {save: saveSalesDocApi, isSaving: salesDocSaving} = useSaveSalesDoc();
  const {updateCache: updateSalesDocCache} = useSalesDocCache();
  const {fetch: fetchSalesDocApi, isLoading: salesDocLoading} = useFetchSalesDoc();
  const {fetch: fetchCustomerApi} = useFetchCustomer();
  const {fetch: fetchDecorationApi} = useFetchDecoration();
  const {fetch: fetchProductApi} = useFetchProduct();
  const {fetch: fetchVendorApi} = useFetchVendor();
  const {data: {markups: _markups}, isLoading: markupsLoading} = useGetMarkups();
  const [markups, setMarkups] = useState(null);
  const [autoSaveCreatedAt, setAutoSaveCreatedAt] = useState(null);
  const loadedObjectsRef = useRef(new Set());
  const autoSaveKeyRef = useRef(autoSaveKey);
  const {company} = useCompany(salesDoc?.companyTradingEntityId ?? salesDoc?.customer?.companyTradingEntityId);

  // Whenever the SalesDoc is set, it will be bound to the salesDoc state. Note that this function must have no dependencies,
  // to ensure that it does not change once created, to avoid stale versions being used.
  const setSalesDoc = useCallback((newSalesDoc, options = {}) => {
    if (!autoSaveKeyRef.current && (newSalesDoc.number || newSalesDoc.docTemplateId)) {
      autoSaveKeyRef.current = newSalesDoc.number ? `salesdoc|${newSalesDoc.number}` : `salesdoc|${newSalesDoc.docTemplateId}|${randomString()}`;
    }

    const docMeta = {
      docTemplateId: newSalesDoc.docTemplateId,
      number: newSalesDoc.number,
      customer: newSalesDoc.customer?.name,
      contact: fullName(newSalesDoc.contact),
      templateName: newSalesDoc.docTemplateName,
      typeName: newSalesDoc.docTypeName,
    };

    if (typeof newSalesDoc === 'function') {
      _setSalesDoc((prevSalesDoc) => {
        newSalesDoc = newSalesDoc(prevSalesDoc);

        if (!options.noChange && autoSaveKeyRef.current) {
          const record = AutoSaveService.saveRecord(autoSaveKeyRef.current, newSalesDoc.forAutoSave(), docMeta);
          setChanged(true);
          setAutoSaveCreatedAt(record.meta.createdAt);
        }

        return newSalesDoc.setNotify(setSalesDoc);
      });
    } else {
      _setSalesDoc(newSalesDoc.setNotify(setSalesDoc));

      if (!options.noChange && autoSaveKeyRef.current) {
        const record = AutoSaveService.saveRecord(autoSaveKeyRef.current, newSalesDoc.forAutoSave(), docMeta);
        setChanged(true);
        setAutoSaveCreatedAt(record.meta.createdAt);
      }
    }
  }, []);

  const loadSalesDoc = useCallback(async ({refetchNumber} = {}) => {
    let loadedSalesDoc;
    let autoSave;
    if (refetchNumber) {
      loadedSalesDoc = SalesDoc.fromApi((await fetchSalesDocApi(refetchNumber)).salesDoc);
    } else if (autoSaveKeyRef.current) {
      autoSave = AutoSaveService.loadRecord(autoSaveKeyRef.current);
    } else if (salesDocNumber && revisionId) {
      loadedSalesDoc = SalesDoc.fromApi((await fetchSalesDocApi(salesDocNumber, {revisionId})).salesDoc);
      setChanged(true);
    } else if (salesDocNumber) {
      autoSave = AutoSaveService.loadRecord(`salesdoc|${salesDocNumber}`);
      if (!autoSave?.record) {
        loadedSalesDoc = SalesDoc.fromApi((await fetchSalesDocApi(salesDocNumber)).salesDoc, {cleanConvert: action === 'convert'});
      }
    } else if (templateId === 'presentation') {
      loadedSalesDoc = SalesDoc.createEmpty(user, {presentation: true});
    } else if (templateId === 'quote') {
      loadedSalesDoc = SalesDoc.createEmpty(user);
    } else if (templateId) {
      if (/^[0-9]+$/.test(templateId)) {
        // If the template ID is a document number, we are making a copy
        loadedSalesDoc = SalesDoc.fromApi((await fetchSalesDocApi(templateId, {params: {copy: true}})).salesDoc, {cleanDuplicate: true});
        setChanged(true);
      } else {
        loadedSalesDoc = SalesDoc.fromTemplate((await fetchSalesDocApi(templateId)).salesDoc, user);
      }
    } else {
      // No parameters at all, load an empty quote
      loadedSalesDoc = SalesDoc.createEmpty(user);
    }

    if (autoSave?.record) {
      setChanged(true);
      loadedSalesDoc = SalesDoc.fromAutoSave(autoSave.record, user);
      setAutoSaveCreatedAt(autoSave.meta.createdAt);
    }

    if (loadedSalesDoc) {
      if (loadedSalesDoc.documentType === SalesDoc.Type.CLASSIC_QUOTE) {
        // Converting an old document, there are always changes
        setChanged(true);
      }
      if (!autoSave?.record) {
        // Only mark the products as loaded when they came from the API
        loadedObjectsRef.current.clear();
        [
          ...Object.keys(loadedSalesDoc.products ?? {}),
          ...Object.keys(loadedSalesDoc.decorations ?? {}),
          ...Object.keys(loadedSalesDoc.vendors ?? {})
        ]
          .forEach((key) => loadedObjectsRef.current.add(key));
      }
      if (loadedSalesDoc.isPresentation() && !featureFlags.ShoppingCart.enabled()) {
        loadedSalesDoc.template.cartButtonEnabled = false;
      }
      _setSalesDoc(loadedSalesDoc.setMarkups(markups).setCompany(company).setNotify(setSalesDoc));
    }
  }, [action, company, fetchSalesDocApi, markups, revisionId, salesDocNumber, setSalesDoc, templateId, user]);

  // This effect handles the initial load, either creating a new SalesDoc, loading a SalesDoc
  // from the server, or loading a SalesDoc from the autoSave
  useEffect(() => {
    loadSalesDoc().catch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSaveKey, salesDocNumber, templateId]);

  const refetchSalesDoc = useCallback(() => {
    if (salesDoc.number) {
      loadSalesDoc({refetchNumber: salesDoc.number}).catch();
    }
  }, [loadSalesDoc, salesDoc?.number]);

  const createSalesDoc = useCallback((template) => {
    if (action === 'convert') {
      salesDoc.switchTemplate(template);
    } else {
      const newSalesDoc = SalesDoc.fromTemplate(template, user);
      loadedObjectsRef.current.clear();
      _setSalesDoc(newSalesDoc.setMarkups(markups).setCompany(company).setNotify(setSalesDoc));
    }
  }, [action, company, markups, salesDoc, setSalesDoc, user]);

  // This effect will load the full customer object when needed
  useEffect(() => {
    // If the customer changes, and the contacts have not been loaded, fetch the full customer now
    if (salesDoc && salesDoc.customerId) {
      if (salesDoc.customer?._id !== salesDoc.customerId || !salesDoc.customer?.contacts?.length) {
        fetchCustomerApi(salesDoc.customerId)
          .then(({customer}) => customer && _setSalesDoc((prevSalesDoc) => prevSalesDoc.addCustomerFromApi(customer)));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesDoc?.customerId, salesDoc?.contactId]);

  // This effect will create a Markups model object whenever the markups change
  useEffect(() => {
    if (_markups) {
      setMarkups(Markups.fromApi(_markups));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_markups]);

  // This effect will update the salesdoc with the latest markups
  useEffect(() => {
    if (markups && salesDoc && salesDoc.markups !== markups) {
      salesDoc.setMarkups(markups);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markups, salesDoc]);

  // This effect will set teh company object whenever it changes, it will not update anything
  useEffect(() => {
    salesDoc?.setCompany(company);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  // This effect loads any objects (products, decorations, vendors) that are referenced but not downloaded yet
  const products = salesDoc?.products ?? {};
  const vendors = salesDoc?.vendors ?? {};
  const decorations = salesDoc?.decorations ?? {};
  useEffect(() => {
    const loadedObjects = loadedObjectsRef.current;

    // Figure out what needs to be loaded, and mark them as loaded, then try to load them in the background
    const loadProducts = Object.keys(products).filter((id) => !loadedObjects.has(id));
    const loadDecorations = Object.keys(decorations).filter((id) => !loadedObjects.has(id));
    [...loadProducts, ...loadDecorations].forEach((id) => loadedObjects.add(id));
    const loadVendorsMap = {...vendors};

    (async () => {
      // First do products in order and awaiting the results
      for await (const productId of loadProducts) {
        const {product} = await fetchProductApi(productId);
        if (product) {
          if (product.vendor?._id) {
            // The product loaded a vendor, lets make sure we mark it loaded and don't load it again
            loadedObjects.add(product.vendor._id);
            loadVendorsMap[product.vendor._id] = product.vendor;
          }
          _setSalesDoc((prevSalesDoc) => prevSalesDoc.addProduct(product));
        } else {
          // If there was an error in fetch, remove it from the loaded set
          loadedObjects.delete(productId);
        }
      }

      // Now decorations
      for await (const decorationId of loadDecorations) {
        const {decoration} = await fetchDecorationApi(decorationId);
        if (decoration) {
          if (decoration.vendor?._id) {
            // The decoration loaded a vendor, lets make sure we mark it loaded and don't load it again
            loadedObjects.add(decoration.vendor._id);
            loadVendorsMap[decoration.vendor._id] = decoration.vendor;
          }
          _setSalesDoc((prevSalesDoc) => prevSalesDoc.addDecorationsFromApi(decoration));
        } else {
          // If there was an error in fetch, remove it from the loaded set
          loadedObjects.delete(decorationId);
        }
      }

      // Now vendors
      const loadVendors = Object.values(loadVendorsMap).filter(({_id, name}) => !loadedObjects.has(_id) || !name).map(({_id}) => _id);
      loadVendors.forEach((id) => loadedObjects.add(id));
      for await (const vendorId of loadVendors) {
        const {vendor} = await fetchVendorApi(vendorId);
        if (vendor) {
          _setSalesDoc((prevSalesDoc) => prevSalesDoc.addVendorsFromApi(vendor));
        } else {
          // If there was an error in fetch, remove it from the loaded set
          loadedObjects.delete(vendorId);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decorations, products, vendors]);

  const deleteAutoSave = useCallback(() => {
    if (autoSaveKeyRef.current) {
      AutoSaveService.removeRecord(autoSaveKeyRef.current);
      autoSaveKeyRef.current = null;
      setAutoSaveCreatedAt(null);
    }
  }, []);

  const saveSalesDoc = useCallback(async ({updateJob, updateInvoice}) => {
    const res = await saveSalesDocApi({id: salesDoc._id, salesDoc: salesDoc.forApi()}, {params: {updateJob, updateInvoice}});
    if (res) {
      setChanged(false);
      updateSalesDocCache({id: salesDocNumber, ...res});
      if (!salesDoc._id || !salesDoc.number || salesDoc.documentType === SalesDoc.Type.CLASSIC_QUOTE) {
        _setSalesDoc((prevSalesDoc) => prevSalesDoc.copyWith({
          _id: res.salesDoc._id,
          number: res.salesDoc.number,
          documentType: res.salesDoc.documentType,
        }));
      }
      if (autoSaveKeyRef.current) {
        AutoSaveService.removeRecord(autoSaveKeyRef.current);
        setAutoSaveCreatedAt(null);
      }
    }
    return res;
  }, [salesDoc, salesDocNumber, saveSalesDocApi, updateSalesDocCache]);

  const _autoSaveKey = autoSaveKeyRef.current;
  return useMemo(() => ({
    autoSaveCreatedAt,
    autoSaveKey: _autoSaveKey,
    changed,
    createSalesDoc,
    deleteAutoSave,
    loading: salesDocLoading || markupsLoading,
    salesDoc,
    salesDocNumber,
    salesDocRevision: revisionId,
    saveSalesDoc,
    refetchSalesDoc,
    saving: salesDocSaving,
  }), [
    autoSaveCreatedAt,
    _autoSaveKey,
    changed,
    createSalesDoc,
    deleteAutoSave,
    salesDocLoading,
    markupsLoading,
    revisionId,
    salesDoc,
    salesDocNumber,
    saveSalesDoc,
    refetchSalesDoc,
    salesDocSaving
  ]);
}
