import React, {useCallback} from 'react';
import {ToolboxDrawer, ToolboxSection} from '../../../componentsLib/Popovers';
import {Column, Row, Table, TableCell, TableRow, usePageContext} from '../../../componentsLib/Layout';
import {SalesDocItem} from '../Models/SalesDoc';
import {BodyText, HeadingText} from '../../../componentsLib/Text';
import {Button, ButtonTheme, ImageThumbnail, TextInput, ToolTip} from '../../../componentsLib/Basic';
import {colors, registerGlobalStyle} from '../../../theme';
import {asCurrencyStringCommaSeparated, valueFromEvent} from '../../../utils';
import {Chip} from '../../../componentsLib/Chips';
import {stripHTML} from '../../../utils/stripHtml';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Info as InfoIcon,
  ShoppingCart as ShoppingCartIcon,
} from '@mui/icons-material';
import {SalesDocSections} from '../Components/SalesDocSection';

registerGlobalStyle('.customer-cart-drawer', (theme) => ({
  '.customer-cart-content': {
    '.variant-table': {
      'td': {padding: theme.spacing(1, 1.5)},
      '.text-body': {margin: theme.spacing(.5, 1.75, .5, .5)},
      '.total-row': {background: theme.colors.background.empty}
    }
  },
  '.customer-cart-table': {
    'svg': {
      color: theme.colors.palette.greyLighter,
      margin: theme.spacing(1),
      cursor: 'pointer'
    },
  },
  '.chip-container': {columnGap: theme.spacing(1)},
  '.selectable-chip': {
    background: theme.colors.background.white,
    border: `1px solid ${theme.colors.border.dark}`,
    overflow: 'visible',
    position: 'relative',
    width: 'fit-content',
    '.text-body': {fontSize: 14},
    'svg': {color: theme.colors.text.medium},
    '&:hover': {
      border: `1px solid ${theme.colors.border.main}`,
      '.selection-action-buttons': {opacity: 1}
    },
  },
  '.selectable-chip.selected': {
    background: theme.colors.palette.green,
    border: `1px solid ${theme.colors.border.transparent}`,
    color: theme.colors.text.white,
    'svg': {color: theme.colors.text.white},
  },
  '.toolbox-drawer-content': {padding: theme.spacing(2, 0),},
  '.customer-cart-footer': {
    columnGap: theme.spacing(1),
    '.customer-cart-action-buttons': {
      paddingTop: theme.spacing(1),
      '.button': {width: '100%'},
      '.button-theme:has(> .button)': {width: '100%'},
    },
    '.customer-cart-totals': {
      position: 'absolute',
      top: theme.spacing(-13),
      width: '100%',
      paddingRight: theme.spacing(6),
      textAlign: 'right',
      'div': {
        display: 'flex',
        justifyContent: 'end',
        '.table': {
          '.table-row': {
            'td:last-of-type': {paddingLeft: theme.spacing(3)},
            'td': {
              padding: theme.spacing(1, 0),
              '.text-body': {
                textAlign: 'right',
                fontSize: 20
              },
            },
          },
        },
      }
    }
  },

}));

export function ShoppingCartDrawer() {
  const {selection, cartDoc, cartOpen, setCartOpen} = usePageContext();

  return (
    <ToolboxDrawer
      className={'customer-cart-drawer'}
      open={cartOpen}
      onOpenChange={setCartOpen}
      width={800}
      noBack
      drawOpenIcon={ShoppingCartIcon}
      drawCloseIcon={ShoppingCartIcon}
    >
      {cartDoc &&
        <Column>
          {selection.groupId &&
            <>
              <AddToCartSection />
              <AddToCartFooterSection />
            </>
          }

          {!selection.groupId &&
            <>
              <CustomerCartSection />
              <CustomerCartFooterSection />
            </>
          }
        </Column>
      }
    </ToolboxDrawer>
  );
}

const CustomerCartSection = () => {
  const {cartDoc, setCartOpen, setSelection} = usePageContext();

  const variantItemsInCart = cartDoc.getGroupIds().map((groupId) => cartDoc.getFirstProductVariantItemInGroup(groupId))?.filter(Boolean);
  const cartSummary = cartDoc.summary;

  const handleEditGroup = useCallback((item) => {
    // Setting selection here will send cart back to the item
    setSelection({groupId: item.groupId, section: SalesDocSections.product, itemId: item.itemId, variantId: item.variantId});
  }, [setSelection]);

  const handleDeleteGroup = useCallback((e, groupId) => {
    e.stopPropagation();
    cartDoc.deleteGroup(groupId);
  }, [cartDoc]);

  return (
    <ToolboxSection noSep>
      <Column gap>
        <Row>
          <HeadingText x24 dark text={'My Cart'} />
          <Button navNegative suffix={'close'} onClick={() => setCartOpen(false)} />
        </Row>

        <Row divider />

        <Table className={'customer-cart-table'}>
          <TableRow>
            <TableCell>
              <BodyText dark>Item</BodyText>
            </TableCell>
            <TableCell alignRight>
              <BodyText dark>Quantity</BodyText>
            </TableCell>
            <TableCell alignRight>
              <BodyText dark>Total</BodyText>
            </TableCell>
            <TableCell />
          </TableRow>
          {variantItemsInCart.map((item) => (
            <TableRow key={item.groupId}>
              <TableCell>
                <Row alignCenter>
                  <ImageThumbnail size={120} imageUrl={item.images[0]} />
                  <BodyText dark text={item.name} />
                </Row>
              </TableCell>
              <TableCell alignRight>
                <Row justifyRight alignCenter>
                  <BodyText>{cartSummary.quantities[item.groupId].quantity}</BodyText>
                  <EditIcon onClick={() => handleEditGroup(item)} />
                </Row>
              </TableCell>
              <TableCell alignRight>
                <BodyText className={'currency-prefix'}>{asCurrencyStringCommaSeparated(cartSummary.summary[item.groupId].totalRevenue)}</BodyText>
              </TableCell>
              <TableCell alignRight>
                <DeleteIcon onClick={(e) => handleDeleteGroup(e, item.groupId)} />
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </Column>
    </ToolboxSection>
  );
};

const CustomerCartFooterSection = () => {
  const {salesDoc, cartDoc, setCartOpen} = usePageContext();

  return (
    <ToolboxSection stickyFooter className={'customer-cart-footer'}>

      <div className={'customer-cart-totals'}>
        <Row divider />
        <div>
          <Table>
            <TableRow>
              <TableCell text={'Subtotal:'} />
              <TableCell>
                <BodyText dark className={'currency-prefix'}>{asCurrencyStringCommaSeparated(cartDoc.summary.subTotal)}</BodyText>
              </TableCell>
            </TableRow>
          </Table>
        </div>
        <BodyText descriptive>Taxes calculated at checkout</BodyText>
      </div>

      <Row justifySpaceBetween gap className={'customer-cart-action-buttons'}>
        <Button navStandard text={'CONTINUE SHOPPING'} onClick={() => setCartOpen(false)} />
        <ButtonTheme color={salesDoc.template.cartButtonColor}>
          <Button navMain text={'CHECK OUT'} caps={false} />
        </ButtonTheme>
      </Row>

    </ToolboxSection >
  );
};

const AddToCartSection = () => {
  const {salesDoc, cartDoc, addItemToCart, selection, setCartOpen} = usePageContext();

  const variantItemsInCart = cartDoc.getVariantItems(selection.variantId);
  const decorationItemsInCart = cartDoc.getItemsInGroup(selection.groupId).filter((item) => item.type === SalesDocItem.Type.DECORATION && !item.rollupSellPrice);
  const additionalCostItemsInCart = cartDoc.getItemsInGroup(selection.groupId).filter((item) => item.type === SalesDocItem.Type.ADDITIONAL_COST && !item.rollupSellPrice && !item.isDecorationSetupCost());
  const cartSummary = cartDoc.summary;

  const variantItems = salesDoc.getVariantItems(selection.variantId);
  const firstVariant = variantItems?.[0];
  const decorationItems = salesDoc.getItemsInGroup(selection.groupId).filter((item) => item.type === SalesDocItem.Type.DECORATION && !item.rollupSellPrice);
  const firstDecoration = decorationItems?.[0];
  const additionalCostItems = salesDoc.getItemsInGroup(selection.groupId).filter((item) => item.type === SalesDocItem.Type.ADDITIONAL_COST && !item.rollupSellPrice && !item.isDecorationSetupCost());
  const firstAdditionalCost = additionalCostItems?.[0];

  const handleGetCartVariant = useCallback((color, size) => (
    variantItemsInCart.find((v) => v.color === color && v.size === size)
  ), [variantItemsInCart]);

  const handleGetCartVariantQuantity = useCallback((color, size) => (
    handleGetCartVariant(color, size)?.quantity
  ), [handleGetCartVariant]);

  const handleUpsertVariantToCart = useCallback(({color, size, quantity}) => {
    const variant = handleGetCartVariant(color, size);
    if (variant) {
      variant.setQuantity(quantity);
    } else {
      addItemToCart({item: variantItems[0], color, size, quantity});
    }
  }, [addItemToCart, handleGetCartVariant, variantItems]);

  const handleToggleAddOnsToCart = useCallback((item) => {
    const cartItem = cartDoc.items.find((ci) => ci._id === item._id);
    if (cartItem) {
      cartItem.delete();
    } else {
      addItemToCart({item});
    }
  }, [addItemToCart, cartDoc]);

  return (
    <ToolboxSection noSep className={'customer-cart-content'}>
      <Column gap>
        <Row>
          <HeadingText x24 dark text={'Add to Cart'} />
          <Button navNegative suffix={'close'} onClick={() => setCartOpen(false)} />
        </Row>

        <Row divider />

        {firstVariant &&
          // Variant Quantity Table
          <>
            <HeadingText x24 dark text={firstVariant.name} />
            <BodyText dark text={'Choose Your Variants'} />
            <Table bodyBorder className={'variant-table'}>
              <TableRow>
                <TableCell>
                  <BodyText dark>Colors</BodyText>
                </TableCell>
                {firstVariant.sizes.map((size) => <TableCell key={size} text={size} alignRight />)}
              </TableRow>
              {firstVariant.colors.map((color) =>
                <TableRow key={color}>
                  <TableCell text={color} />
                  {firstVariant.sizes.map((size) =>
                    <TableCell key={size} alignRight>
                      <TextInput type={'number'} value={handleGetCartVariantQuantity(color, size)} onChange={(e) => handleUpsertVariantToCart({color, size, quantity: valueFromEvent(e)})} />
                    </TableCell>
                  )}
                </TableRow>
              )}
              <TableRow>
                <TableCell>
                  <BodyText dark>Sub Total</BodyText>
                </TableCell>
                {firstVariant.sizes.map((size) =>
                  <TableCell key={size} alignRight>
                    <BodyText>{cartSummary.summary[firstVariant.variantId]?.[size]?.quantity}</BodyText>
                  </TableCell>
                )}
              </TableRow>
              <TableRow className={'total-row'}>
                <TableCell colSpan={1 + firstVariant.sizes?.length}>
                  <Row justifySpaceBetween>
                    <BodyText><b>Total</b> (minimum order units)</BodyText>
                    <BodyText>{cartSummary.quantities[selection.groupId]?.quantity}</BodyText>
                  </Row>
                </TableCell>
              </TableRow>
            </Table>
          </>
        }

        {(firstDecoration || firstAdditionalCost) &&
          <>
            <HeadingText x16 dark text={'Add Ons'} />

            {firstDecoration &&
              <Column>
                <BodyText dark text={'Included Add-Ons'} />
                <AddOnsChipListSelectable items={decorationItems} selectedItems={decorationItemsInCart} setSelected={handleToggleAddOnsToCart} />
              </Column>
            }
            {firstAdditionalCost &&
              <Column>
                <BodyText dark text={'Other Available Add-Ons'} />
                <AddOnsChipListSelectable items={additionalCostItems} selectedItems={additionalCostItemsInCart} setSelected={handleToggleAddOnsToCart} />
              </Column>
            }
          </>
        }
      </Column>
    </ToolboxSection>
  );
};

const AddToCartFooterSection = () => {
  const {salesDoc, cartDoc, selection, setSelection, setCartOpen} = usePageContext();

  const variantItems = salesDoc.getVariantItems(selection.variantId);
  const firstVariant = variantItems?.[0];
  const cartSummary = cartDoc.summary;

  const handleDeleteGroup = useCallback(() => {
    cartDoc.deleteGroup(firstVariant.groupId);
  }, [cartDoc, firstVariant]);

  return (
    <ToolboxSection stickyFooter className={'customer-cart-footer'}>

      <div className={'customer-cart-totals'}>
        <Row divider />
        <div>
          <Table>
            <TableRow>
              <TableCell text={'Subtotal:'} />
              <TableCell>
                <BodyText dark className={'currency-prefix'}>{asCurrencyStringCommaSeparated(cartSummary.summary[selection.groupId]?.totalRevenue ?? 0)}</BodyText>
              </TableCell>
            </TableRow>
          </Table>
        </div>
        <BodyText descriptive>Taxes calculated at checkout</BodyText>
      </div>

      {firstVariant &&
        <Row justifySpaceBetween gap className={'customer-cart-action-buttons'}>
          <ButtonTheme color={colors.palette.red}>
            <Button navStandard text={'REMOVE FROM CART'} onClick={handleDeleteGroup} />
          </ButtonTheme>
          <Button navStandard text={'CONTINUE SHOPPING'} onClick={() => setCartOpen(false)} />
          <ButtonTheme color={salesDoc.template?.cartButtonColor}>
            <Button navMain text={'VIEW CART'} caps={false} onClick={() => setSelection({})} />
          </ButtonTheme>
        </Row>
      }

    </ToolboxSection >
  );
};

const AddOnsChipListSelectable = ({items, selectedItems, setSelected}) => (
  <Row wrap className={'chip-container'}>
    {Object.entries(items).map(([key, item]) => {
      const tip = `${item.position ? `position: ${item.position}\n` : ''}${item.color ? `color: ${item.color}\n` : ''}${item.size ? `size: ${item.size}\n` : ''}`;
      const selected = selectedItems?.some(({_id}) => _id === item._id);
      return (
        <Chip
          key={key}
          onClick={() => setSelected(item)}
          className={['selectable-chip', selected && 'selected']}
        >
          {item.name ?? stripHTML(item.description) ?? ''}
          {tip.length > 0 &&
            <ToolTip top tip={<div style={{whiteSpace: 'pre-line'}}>{tip}</div>}>
              <InfoIcon fontSize='small' />
            </ToolTip>
          }
        </Chip>
      );
    })
    }
  </Row>
);
