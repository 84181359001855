import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {useParams} from 'react-router';
import {Page, Row} from '../../../componentsLib/Layout';
import {useCompany, useLocalStorageState, useSnackbar, useUser} from '../../../hooks';
import {useAnonGetCompany, useAnonCommentSalesDoc, useAnonMarkAsViewedSalesDoc, useAnonAcceptSalesDoc} from '../../../hooks/api';
import {registerGlobalStyle} from '../../../theme';
import {useSalesDocGuestState} from '../State/useSalesDocGuestState';
import {PresentationView} from '../Views/PresentationView';
import {PdfPrintService} from '../../../services';
import {AttentionConfirmDialog} from '../../../componentsLib/Popovers';
import {BodyText} from '../../../componentsLib/Text';
import Lottie from 'react-lottie';
import successAlertIcon from '../../../assets/lotties/success-alert-icon.json';
import {SalesDocView} from '../Views/SalesDocView';
import {BusySpinner} from '../../../componentsLib/Basic';
import {SalesDocSections} from '../Components/SalesDocSection';
import {ShoppingCartDrawer} from '../Cart/ShoppingCartDrawer';
import {useSalesDocCartState} from '../State/useSalesDocCartState';

registerGlobalStyle('.salesdoc-guest-page', (theme) => ({
  '&.salesdoc-guest-page:has(.pdf-view)': {'.page-scroll-container': {overflow: 'hidden'}},
  '.salesdoc-view': {
    display: 'flex',
    justifyContent: 'center',
  },
  '.loading-row': {height: '100%'},
  '.salesdoc-container': {height: '100%',},
  '&.presentation': {
    background: theme.colors.background.white,
    display: 'flex',
    justifyContent: 'center',
    minWidth: 300,
    '.page-scroll-container': {
      display: 'flex',
      justifyContent: 'center'
    },
    '.presentation-view': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: 1420,
      marginBottom: theme.spacing(10),
      height: 'max-content',
      '.product-gallery': {
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(1),
        paddingLeft: 0
      }
    },
  }
}));

registerGlobalStyle('.salesdoc-accept-comment-success-dialog', (theme) => ({
  '.MuiDialog-paper.MuiPaper-root': {maxWidth: 400},
  svg: {marginTop: 0},
  '.modal-dialog-content': {
    '&>.text-body': {
      fontSize: '1rem',
      padding: theme.spacing(0, 5, 2.5),
    },
  },
  '.actions': {justifyContent: 'center'}
}));

export function SalesDocGuestPage({salesDocId: salesDocIdProp, hideActionButtons, printPdf, onPrintPdfComplete}) {
  const {salesDocId: salesDocIdParam} = useParams();
  const {salesDoc, isLoading: salesDocLoading, refetchSalesDoc, updateCache} = useSalesDocGuestState(salesDocIdProp ?? salesDocIdParam);
  const {data} = useAnonGetCompany(salesDoc?.companyId);
  const {user} = useUser();
  const {accept: acceptSalesDocApi} = useAnonAcceptSalesDoc();
  const {company} = useCompany(salesDoc?.companyTradingEntityId ?? salesDoc?.customer?.companyTradingEntityId, data.company);
  const currencySymbol = company.currencySymbol;
  const [openSuccessDialog, setOpenSuccessDialog] = useState({open: false, type: ''});
  const {comment: commentSalesDocAPI, isInProgress: commentSaving} = useAnonCommentSalesDoc();
  const {viewed: markAsViewedSalesDocAPI} = useAnonMarkAsViewedSalesDoc();
  const [pdfLoading, setPdfLoading] = useState(false);
  const snackbar = useSnackbar();
  const [commentText, setCommentText] = useState('');
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const salesDocPdf = useRef(null);
  const [selection, setSelection] = useState({section: SalesDocSections.documentHeader});
  const [markedAsViewed, setMarkedAsViewed] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  const {cartDoc, addItemToCart} = useSalesDocCartState({salesStore: salesDoc});

  const isBuilder = false;
  const isPresentation = salesDoc?.isPresentation();
  const isGuestView = !user._id;

  const [zoomProps, setZoomProps] = useState({zoom: 100, translateX: 0});
  const [autoZoom, _setAutoZoom] = useLocalStorageState('salesdoc|guestView|autoZoom', true);
  const zoomRef = useRef(null);

  const setZoom = useCallback((newZoom) => {
    if (zoomRef.current) {
      const paperElem = zoomRef.current.querySelector('.salesdoc-paper');
      if (paperElem) {
        const containerWidth = zoomRef.current.offsetWidth;
        const paperWidth = paperElem.offsetWidth;
        const availWidth = containerWidth;
        const zoomFit = 100 * (availWidth - 16) / paperWidth;
        const zoom = newZoom === 'fit' ? Math.min(100, zoomFit) : newZoom;
        const translateX = Math.max(0, (availWidth - paperWidth * zoom / 100) / 2);
        setZoomProps({zoom, translateX});
        _setAutoZoom((prev) => newZoom === 'fit' ? prev : false);
      }
    }
  }, [_setAutoZoom]);

  const setAutoZoom = useCallback((az) => {
    if (az) {
      setZoom('fit');
    }
    _setAutoZoom(az);
  }, [_setAutoZoom, setZoom]);

  useLayoutEffect(() => {
    setZoom(autoZoom ? 'fit' : 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomRef.current != null, salesDoc != null]);

  const handleDownloadPdf = useCallback(async () => {
    if (salesDocPdf.current) {
      setZoom(100);
      setShowComment(false);
      setPdfLoading(true);
      const pdf = await PdfPrintService.print(salesDocPdf.current);
      if (!document.body.contains(salesDocPdf.current)) {
        snackbar.showSnackbarError('PDF download canceled');
      } else if (pdf) {
        await pdf.save(`${salesDoc.docTypeName}-${salesDoc.number}`);
      } else {
        snackbar.showSnackbarError();
      }
      setPdfLoading(false);
      setZoom(autoZoom ? 'fit' : zoomProps.zoom);
    }
  }, [autoZoom, salesDoc, setZoom, snackbar, zoomProps]);

  useEffect(() => {
    (async () => {
      if (printPdf && salesDocPdf.current && !pdfLoading) {
        await handleDownloadPdf();
        onPrintPdfComplete();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesDoc]);

  useEffect(() => {
    if (salesDoc?._id && isGuestView && !markedAsViewed) {
      markAsViewedSalesDocAPI({id: salesDoc?._id}, {successMessage: false});
      setMarkedAsViewed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesDoc]);

  const handleAccept = useCallback(() => {
    salesDoc.accept();
    acceptSalesDocApi({id: salesDoc?._id}, {successMessage: false});
    //success dialog
    setOpenSuccessDialog({open: true, type: 'accept'});
  }, [salesDoc, acceptSalesDocApi]);

  const handlePaymentSuccess = useCallback(() => {
    salesDoc.accept();
    refetchSalesDoc();
  }, [refetchSalesDoc, salesDoc]);

  const saveComment = useCallback(async ({groupId}) => {
    const firstItem = salesDoc.getMainItemInGroup(groupId);
    const res = await commentSalesDocAPI(
      {id: salesDoc?._id, commentText: groupId ? `Re: ${firstItem.name} - ${commentText}` : commentText},
      {
        successMessage: false,
        errorMessage: ({message}) => message,
      }
    );
    if (res) {
      //success dialog
      setOpenSuccessDialog({open: true, type: 'comment'});
      setShowComment(false);
      setCommentText('');
    }
  }, [salesDoc, commentText, commentSalesDocAPI]);

  const context = useMemo(
    () => ({
      salesDoc,
      cartDoc,
      addItemToCart,
      cartOpen,
      setCartOpen,
      company,
      currencySymbol,
      refetchSalesDoc,
      isBuilder,
      hideActionButtons,
      printPdf,
      onPrintPdfComplete,
      handleDownloadPdf,
      pdfLoading,
      setShowComment,
      handleAccept,
      isPaymentDialogOpen,
      setIsPaymentDialogOpen,
      handlePaymentSuccess,
      showComment,
      commentText,
      setCommentText,
      saveComment,
      commentSaving,
      salesDocPdf,
      selection,
      setSelection,
      updateCache,
      zoom: {
        zoom: zoomProps.zoom,
        setZoom,
        auto: autoZoom,
        setAuto: setAutoZoom,
        fitScreen: () => setZoom('fit'),
      },
    }),
    [
      salesDoc,
      cartDoc,
      addItemToCart,
      cartOpen,
      setCartOpen,
      company,
      currencySymbol,
      refetchSalesDoc,
      isBuilder,
      hideActionButtons,
      printPdf,
      onPrintPdfComplete,
      handleDownloadPdf,
      pdfLoading,
      setShowComment,
      handleAccept,
      isPaymentDialogOpen,
      setIsPaymentDialogOpen,
      handlePaymentSuccess,
      showComment,
      commentText,
      setCommentText,
      saveComment,
      commentSaving,
      salesDocPdf,
      selection,
      setSelection,
      updateCache,
      zoomProps,
      setZoom,
      autoZoom,
      setAutoZoom,
    ]
  );

  return (
    <Page testId={'salesdoc-guest-page'} className={['salesdoc-guest-page', isPresentation && 'presentation']} context={context}>
      {!salesDoc && salesDocLoading &&
        <Row justifyCenter alignCenter className={'loading-row'}>
          <BusySpinner large center message={'Loading'} />
        </Row>
      }
      {salesDoc && !isPresentation &&
        <Row className={'salesdoc-container'} ref={zoomRef} style={{'--zoom': zoomProps.zoom / 100, '--zoom-x': `${zoomProps.translateX}px`}}>
          <SalesDocView />
        </Row>
      }
      {salesDoc && isPresentation && <PresentationView />}
      {salesDoc && openSuccessDialog.open && (
        <AttentionConfirmDialog
          title={openSuccessDialog.type === 'accept' ? `${salesDoc.docTypeName} Accepted` : 'Comment Sent'}
          onClose={() => setOpenSuccessDialog({open: false})}
          onOk={() => setOpenSuccessDialog({open: false})}
          okText={'Ok'}
          cancelText={null}
          className={'salesdoc-accept-comment-success-dialog'}
        >
          {openSuccessDialog.type === 'accept' && <BodyText text={`You accepted ${salesDoc.docTypeName} ${salesDoc.number}.`} />}
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: successAlertIcon,
              rendererSettings: {preserveAspectRatio: 'xMidYMid slice'},
            }}
            height={200}
            width={200}
          />
          {openSuccessDialog.type === 'accept' && <BodyText text={'Thank you for your business, we\'ll be in touch with further updates'} />}
          {openSuccessDialog.type === 'comment' && <BodyText text={'Your comment was successfully added, our team will be notified'} />}
        </AttentionConfirmDialog>
      )}
      {isPresentation && salesDoc?.template.cartButtonEnabled &&
        <ShoppingCartDrawer />
      }
    </Page>
  );
}
