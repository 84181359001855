import {useCallback, useEffect, useMemo, useState} from 'react';
import {useGetSalesDoc, useSaveSalesDoc} from '../../../hooks/api';
import {SalesDocItem} from '../Models/SalesDoc';

export function useSalesDocCartState({salesStore}) {
  // Going to hard code the template ID here for now, until it is part of the template settings
  const {data: {salesDoc: template}, isLoading: cartLoading} = useGetSalesDoc(/*salesDoc?.template.cartTemplateId*/ salesStore ? '66bdc07265176c48337c0854' : undefined);
  const {save: saveSalesDocApi, isSaving: cartSaving} = useSaveSalesDoc();
  const [cartDoc, setCartDoc] = useState(null);

  useEffect(() => {
    if (salesStore && template) {
      const doc = salesStore.createCart({template});
      setCartDoc(doc.setNotify(setCartDoc));
    }
  }, [salesStore, template]);

  const calculateCartPrices = useCallback((cart) => {
    // For the shopping cart we don't really use the salesdoc pricing calculations, we have to be sure that
    // we use the prices and other financial data from the presentation
    const updates = [];
    cart.getGroupIds().forEach((groupId) => {
      const quantity = cart.getProductVariantsInGroup(groupId).reduce((q, v) => q + v.quantity, 0);
      if (quantity > 0) {
        const storeVariant = salesStore.getProductVariantsInGroup(groupId).findLast((variant) => quantity >= variant.quantity);
        updates.push(...cart.getProductVariantsInGroup(groupId).map((variant) => ({itemId: variant.itemId, pricing: storeVariant})));
        cart.getNonProductVariantsInGroup(groupId).forEach((cartItem) => {
          const variantId = salesStore.getItem(cartItem.itemId).variantId;
          const storeItem = salesStore.getVariantItems(variantId).findLast((item) => quantity >= item.quantity);
          updates.push({itemId: cartItem.itemId, pricing: storeItem});
        });
      }
    });
    if (updates.length > 0) {
      cart.cartUpdatePricing(updates);
    }
  }, [salesStore]);

  const addItemToCart = useCallback(({item, quantity, color, size}) => {
    let newCartDoc;
    if (item.type === SalesDocItem.Type.VARIANT) {
      const variant = salesStore.getFirstVariantItem(item.variantId);
      const items = [{...variant, quantity, color, size}];
      if (cartDoc.getProductVariantsInGroup(item.groupId).length === 0) {
        // If this is the first time a product has been added to the group, add all the rolled up decorations and additional costs as well
        salesStore.getPresentationRowIdsInGroup(item.groupId).forEach((variantId) => {
          const storeItem = salesStore.getFirstVariantItem(variantId);
          if (storeItem.rollupSellPrice && (storeItem.type === SalesDocItem.Type.DECORATION || storeItem.type === SalesDocItem.Type.ADDITIONAL_COST)) {
            items.push(storeItem);
          }
        });
      }
      newCartDoc = cartDoc.cartAddItems(items);
    } else if (item.type === SalesDocItem.Type.DECORATION) {
      const decoration = salesStore.getFirstVariantItem(item.variantId);
      const items = [decoration];
      const setup = decoration.getSetupCostItem();
      if (setup) {
        items.push(setup);
      }
      newCartDoc = cartDoc.cartAddItems(items);
    } else if (item.type === SalesDocItem.Type.ADDITIONAL_COST) {
      newCartDoc = cartDoc.cartAddItems(salesStore.getFirstVariantItem(item.variantId));
    }
    if (newCartDoc) {
      calculateCartPrices(newCartDoc);
    }
    return true;
  }, [calculateCartPrices, cartDoc, salesStore]);

  const saveCart = useCallback(async () => {
    const res = await saveSalesDocApi({id: cartDoc._id, salesDoc: cartDoc.forApi()});
    if (res) {
      if (!cartDoc._id || !cartDoc.number) {
        setCartDoc((prevDoc) => prevDoc.copyWith({
          _id: res.salesDoc._id,
          number: res.salesDoc.number
        }));
      }
    }
    return res;
  }, [cartDoc, saveSalesDocApi]);

  const state = useMemo(() => ({
    cartDoc,
    cartLoading,
    saveCart,
    cartSaving,
    addItemToCart,
  }), [addItemToCart, cartDoc, cartLoading, cartSaving, saveCart]);

  console.log('state:', state, salesStore);

  return state;
}
